@import url("https://use.typekit.net/esp8dcq.css");

:root {
  --background-color: #f0f0f0;
  --primary-text-color: #272727;
  --text-hightlight: var(--primary-text-color);
  --text-selection-color: var(--background-color);
  --text-selection-bg: var(--text-hightlight);
}

html {
  font-family: utopia-std, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1.4;
  color: var(--primary-text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

::selection {
  text-shadow: none;
  color: var(--text-selection-color);
  background-color: var(--text-selection-bg);
}

::-moz-selection {
  text-shadow: none;
  color: var(--text-selection-color);
  background-color: var(--text-selection-bg);
}

main {
  margin: 100px;
}

main p:first-child {
  margin-bottom: 1.6em;
  max-width: 960px;
  font-size: 44px;
}

main p:last-child {
  max-width: 700px;
  font-style: italic;
}

a {
  color: var(--primary-text-color);
  text-decoration-line: underline;
  text-decoration-thickness: 0.02em;
  text-underline-offset: 0.194em;
}

@media (max-width: 680px) {
  main {
    margin: 40px;
  }

  main p:first-child {
    font-size: 24px;
  }

  main p:last-child {
    font-size: 18px;
  }
}